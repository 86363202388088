module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":870,"linkImagesToOriginal":true,"withWebp":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"723543738081572"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-112062558-2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Блог по ReactJS: статьи, уроки, примеры, основы, tutorial, docs ⚛️ Frontend онлайн-курсы - ReactWarriors","short_name":"Блог по ReactJS: статьи, уроки, примеры, основы, tutorial, docs ⚛️ Frontend онлайн-курсы - ReactWarriors","description":"Блог про React JavaScript : статьи, уроки, примеры, основы, tutorial, документация ⚛️ Онлайн-курсы по ReactJS - ReactWarriors | Frontend курс и видеоуроки","start_url":"","background_color":"#ffffff","theme_color":"#f26120","display":"standalone","icon":"static/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"globPatterns":["**/*.{js,jpg,png,jpeg,md,html,css}"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-yandex-metrika/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"61706248","webvisor":true,"trackHash":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
